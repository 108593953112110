<template>
  <div>
    <div class="pc">
      <div class="menu-wrapper">
        <div class="container flex-align">
          <div class="unselect" v-for="(menu, idx) in menus" :key="'menu-' + idx">
            <div class="body2 sub margin-right-48" @click="clickLink(menu, true)">{{ menu.name }}</div>
          </div>
        </div>
      </div>
      <div class="container padding-top-24" style="padding-bottom:24px">
        <div class="flex" style="justify-content: space-between">
          <div class="body4 sub3">
            <div class="flex-align margin-bottom-4">
              <div>상호 : 런치팩 주식회사</div>
              <div class="divider"></div>
              <div>대표자 : 염민지</div>
            </div>
            <div class="margin-bottom-4">서울지사 : 서울특별시 강남구 역삼로 175 4층 B호</div>
            <div class="margin-bottom-4">광주본사 : 광주광역시 동구 서남로 14 410호</div>
            <div class="flex-align margin-bottom-4">
              <div>사업자등록번호 : 694-87-01163</div>
              <div class="divider"></div>
              <div>통신판매업신고 : 제 2018-광주동구-0192 호</div>
            </div>
            <div class="flex-align margin-bottom-4">
              <div>개인정보책임자 : 이남호</div>
              <div class="divider"></div>
              <div>전화 : <a href="tel:1544-9247">1544-9247</a></div>
              <div class="divider"></div>
              <div>이메일 : <a href="mailto:support@launchpack.co.kr">support@launchpack.co.kr</a></div>
            </div>
            <div>입금계좌 : 농협은행 301-0234-5038-61</div>
            <div class="margin-top-24">Copyright © Launchpack corp All rights reserved.</div>
          </div>
          <div class="flex" style="gap:16px">
            <a href="http://pf.kakao.com/_mxolKd/chat" target="_blank">
              <img class="unselect" style="width:36px;height:36px;" src="/static/icon/ic_kkt.svg" alt="" />
            </a>
            <a href="https://www.instagram.com/launch.pack/" target="_blank">
              <img class="unselect" style="width:36px;height:36px;" src="/static/icon/ic_insta.svg" alt="" />
            </a>
            <a href="https://blog.naver.com/launchpack" target="_blank">
              <img class="unselect" style="width:36px;height:36px;" src="/static/icon/ic_blog.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile">
      <div class="menu-wrapper">
        <div class="flex-align wrap">
          <div class="unselect" v-for="(menu, idx) in menus" :key="'menu-' + idx">
            <div class="body5 sub2 padding-8" @click="clickLink(menu, true)">{{ menu.name }}</div>
          </div>
        </div>
      </div>
      <div class="container padding-top-24" :style="`padding-bottom: ${$route.path === '/theme_detail' ? 90 : 24}px`">
        <div class="body7 sub3">
          <div class="flex-align margin-bottom-2">
            <div>상호 : 런치팩 주식회사</div>
            <div class="divider"></div>
            <div>대표자 : 염민지</div>
          </div>
          <div class="margin-bottom-2">서울지사 : 서울특별시 강남구 역삼로 175 4층 B호</div>
          <div class="margin-bottom-2">광주본사 : 광주광역시 동구 서남로 14 410호</div>
          <div class="margin-bottom-2">사업자등록번호 : 694-87-01163</div>
          <div class="margin-bottom-2">통신판매업신고 : 제 2018-광주동구-0192 호</div>
          <div class="margin-bottom2">개인정보책임자 : 이남호</div>
          <div class="margin-bottom2">전화 : <a href="tel:1544-9247">1544-9247</a></div>
          <div class="margin-bottom2">
            이메일 : <a href="mailto:support@launchpack.co.kr">support@launchpack.co.kr</a>
          </div>
          <div>입금계좌 : 농협은행 301-0234-5038-61</div>
          <div class="flex" style="margin-top:12px;gap:16px">
            <a href="http://pf.kakao.com/_mxolKd/chat" target="_blank">
              <img class="unselect" style="width:32px;height:32px;" src="/static/icon/ic_kkt.svg" alt="" />
            </a>
            <a href="https://www.instagram.com/launch.pack/" target="_blank">
              <img class="unselect" style="width:32px;height:32px;" src="/static/icon/ic_insta.svg" alt="" />
            </a>
            <a href="https://blog.naver.com/launchpack" target="_blank">
              <img class="unselect" style="width:32px;height:32px;" src="/static/icon/ic_blog.svg" alt="" />
            </a>
          </div>
          <div class="margin-top-24">Copyright © Launchpack corp All rights reserved.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lp-footer',
  mounted() {},
  methods: {
    clickLink(content, isPushLink) {
      this.$store.commit('setTrial', false);
      this.setGa(`푸터 (${content.name})`, '클릭', content.title);
      if (isPushLink) {
        if (content.link.indexOf('mailto') > -1 || content.link.indexOf('mailto') > -1) {
          location.href = content.link;
        } else if (content.link.indexOf('http') === 0) {
          const a = document.createElement('a');
          a.href = content.link;
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          a.parentNode.removeChild(a);
        } else {
          this.$router.push(content.link);
        }
      }
    },
    isALink(link) {
      let type = ['tel', 'mailto', 'http', 'https'];
      let head = link.split(':')[0];
      if (type.indexOf(head) > -1) return true;
      else return false;
    },
  },

  computed: {
    paddingBottom() {
      if (window.innerWidth < 601) {
        if (this.$route.path.search('store/detail') > -1) {
          return 'padding-bottom: 50px;';
        }
      }
      return '';
    },
  },

  data() {
    return {
      containerPaddingBottom: 0,
      menus: [
        {
          name: '서비스 이용약관',
          link: '/term',
        },
        {
          name: '제휴제안',
          link: 'mailto:support@launchpack.co.kr',
        },
        {
          name: '개인정보 처리방침',
          link: '/privacy',
        },
        {
          name: '사업자정보확인',
          link:
            'https://www.ftc.go.kr/www/bizCommView.do?key=232&apv_perm_no=2018359015430200192&pageUnit=10&searchCnd=wrkr_no&searchKrwd=6948701163&pageIndex=1',
        },
        {
          name: 'FAQ',
          link: '/faq',
        },
        {
          name: '새소식',
          link: '/notice',
        },
        {
          name: '1:1문의',
          link: '/inquiry',
        },
      ],
    };
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
/*.lp-footer, .footer {
  !*background-color: rgb(51, 51, 51);*!
  background-color: #292c32;
  color: white;
  clear:both;


}

.lp-footer{
  width:100%;
}

.footer {
  padding-bottom: 3rem;
}



.lp-title {
  color: #a0a0a0;
  margin-bottom: 1.3rem;
}

.lp-cont {
  color: white;
  font-size: 0.9rem;
  margin-bottom: 8px;
}

a {
  color: white;
  cursor: pointer;
}

a:hover {
  color: #cccccc;
}
@media(max-width:768px){
  .lp-footer{
    border-top:none;
  }
}

.column {
  padding: 0;
}*/
.pc
  .menu-wrapper
    padding 24px 0
    border-top 1px solid $gray2
    border-bottom 1px solid $gray2

  .divider
    width 1px
    height 16px
    background-color $gray
    margin 0 12px

.mobile
  .menu-wrapper
    padding 16px
    border-top 1px solid $gray2
    border-bottom 1px solid $gray2
  .divider
    width 1px
    height 12px
    background-color $gray
    margin 0 8px

a
  color $sub3
</style>
